@import '../src/stylesheets/buttons.scss';
@import '../src/stylesheets/inputs.scss';
@import '../src/stylesheets/labels.scss';
@import '../src/stylesheets/dropdowns.scss';
@import '../src/stylesheets/containers.scss';
@import '../src/stylesheets/scroller.scss';

:root {
    --font-regular: 'Open Sans', sans-serif;
    // --font-bold: 'Open Sans', Bold;
    // --font-italic: 'Open Sans', Italic;
    --font-color-standard: #313334;
    --font-color-placeholder: #A4A5A5;

    --color-1: #004358;
    --color-2: #4D8191;
    --color-2-light: #069ECE1A;
    --color-3: #4BB2D2;
    --color-4: #FD7400;
    --color-4-light: #ffe3cc1a;
    --color-5: #FFE11A;
    --color-5-light: #ffe01a1c;
    --color-6: #BEDB39;
    --color-6-light: #F2F8D7;
    --color-7: #313334;
    --color-7-light: #A4A5A5;
    --color-7-light-light: #F2F2F2;
    --color-8: #8DDFF626;
    --color-9: #D0E5EB;
    --main-background-color: #F7F9F9;
    --invalid-color: #FD7400;
    --hover-color: #92e0f823;
    --box-shadow: 0px 3px 15px #6D6D6D21;
    --scroller-color: #D0E5EB;
}

body {
    margin: 0;
    overflow: hidden;
    background-color: var(--main-background-color);
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
