input {
    font-family: var(--font-regular);
    color: var(--font-color-regular);
    font-size: 14px;
    padding: 10px 0px;
    border: none;
    outline: none;
    width: 100%;
    background-color: transparent;
}
input::placeholder {
    font-family: var(--font-regular);
    font-style: italic;
    color: var(--font-color-placeholder);
}

.input_container {
    display: flex;
    align-items: center;
    background-color: white;
    height: 20px;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid var(--color-9);
    transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -ms-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    -webkit-transition: 0.2s ease-in;
    width: calc(100% - 30px);
}
.input_container_invalid {
    border-color: var(--invalid-color);
}
.input_container_readonly {
  display: flex;
  align-items: center;
  background-color: white;
  height: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--color-9);
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  width: calc(100% - 30px);
  cursor: default;
}

.inputfield {
    padding: 15px 20px;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -ms-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    -webkit-transition: 0.2s ease-in;
    width: calc(100% - 30px);
}
.inputfield_invalid {
    border-color: var(--invalid-color);
}
.inputfield_valid {
    border-color: var(--color-6);
}

.text_area {
  background-color: white;
  font-family: var(--font-regular);
  font-size: 14px;
  color: var(--font-color-standard);
  border: 1px solid var(--color-9);
  border-radius: 6px;
  padding: 15px 20px;
  resize: none;
  width: calc(100% - 50px);
  height: 100px;
  resize: none;
  outline: none;
  overflow-y: scroll;
}
.text_area::placeholder {
  font-family: var(--font-regular);
  font-style: italic;
  color: var(--font-color-placeholder);
}

.text_area_placeholder {
  font-family: var(--font-regular);
  font-style: italic;
  color: var(--font-color-placeholder);
}


.input_disabled {
  background-color: var(--color-7-light-light);
  border-color: var(--color-7-light-light);
  cursor: default;
}
