/* Scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 2px;
    background: transparent;
    cursor: pointer;
}
::-webkit-scrollbar-track {
    background: transparent;
    height: 100px;
}
::-webkit-scrollbar-thumb {
    background: var(--scroller-color);
}
::-webkit-scrollbar-thumb:hover {
    background: var(--scroller-color);
}
::-webkit-scrollbar-corner{
    background: transparent;
}
