.btn_submit {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: white;
    background-color: var(--color-1);
    padding: 15px 40px;
    border-radius: 9px;
    font-family: var(--font-regular);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    transition: 0.06s ease-in;
    -moz-transition: 0.06s ease-in;
    -ms-transition: 0.06s ease-in;
    -o-transition: 0.06s ease-in;
    -webkit-transition: 0.06s ease-in;
}
.btn_submit:hover {
  background-color: #00556eec;
}
.btn_submit_inactive {
  color: var(--main-background-color);
  background-color: var(--color-7-light);
  cursor: default;
}
.btn_submit_inactive:hover {
  color: var(--main-background-color);
  background-color: var(--color-7-light);
}

.btn_cancel {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #808080bd;
  background-color: transparent;
  border: 1px solid #80808061;
  padding: 14px 40px;
  border-radius: 9px;
  font-family: var(--font-regular);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: 0.06s ease-in;
  -moz-transition: 0.06s ease-in;
  -ms-transition: 0.06s ease-in;
  -o-transition: 0.06s ease-in;
  -webkit-transition: 0.06s ease-in;
  width: -webkit-fill-available;
}
.btn_cancel:hover {
  	background-color: #8080800c;
}

.btn_lbl {
  font-family: var(--font-regular);
  font-weight: bold;
  font-size: 14px;
  color: var(--color-1);
  padding: 10px 0;
  width: max-content;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.06s ease-in;
  -moz-transition: 0.06s ease-in;
  -ms-transition: 0.06s ease-in;
  -o-transition: 0.06s ease-in;
  -webkit-transition: 0.06s ease-in;
}
.btn_lbl:hover {
  color: var(--color-2);
}
.btn_lbl_inactive {
  color: var(--color-7-light);
  cursor: default;
}
.btn_lbl_inactive:hover {
  color: var(--color-7-light)
}

.btn_tab {
  font-family: var(--font-regular);
  font-weight: bold;
  font-size: 14px;
  color: var(--font-color-standard);
  padding: 15px 20px;
  border-radius: 5px;
  background-color: transparent;
  width: max-content;
  cursor: pointer;
  transition: 0.06s ease-in;
  -moz-transition: 0.06s ease-in;
  -ms-transition: 0.06s ease-in;
  -o-transition: 0.06s ease-in;
  -webkit-transition: 0.06s ease-in;
}
.btn_tab:hover {
  color: var(--color-1);
  background-color: var(--color-2-light);
}
.btn_tab_active {
  color: var(--color-1);
  background-color: var(--color-2-light);
}





/* Radion-Button */
.radio_btn_container {
    display: flex;
  }
  .radio_container {
    display: grid;
    grid-template-columns: 80px 15px;
    margin-bottom: 10px;
    align-items: center;
    margin-right: 40px;
  }
  .radio_container_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 0;
  }
  .radio_lbl {
    font-family: var(--font-regular);
    font-size: 14px;
  }
  .radio_circle {
    display: flex;
    justify-content: center;
    width: 12px;
    height: 12px;
    border: 1px solid var(--color-1);
    padding: 1px;
    border-radius: 10px;
    cursor: pointer;
  }
  .radio_inner_circle_active {
    height: 12px;
    width: 12px;
    padding: 0px;
    border-radius: 12px;
    background-color: var(--color-1);
    opacity: 1;
    transition: 0.2s;
  }
  .radio_inner_circle_not_active {
    height: 8px;
    width: 8px;
    padding: 0px;
    border-radius: 12px;
    background-color: transparent;
    opacity: 0;
    transition: 0.2s;
  }


/* Checkbox */
.checkbox_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .checkboxi {
    width: 5px;
    height: 5px;
    border: 2px solid var(--color-1);
    border-radius: 2px;
    padding: 4px;
    margin-right: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    cursor: pointer;
  }
  .checkboxi_unchecked {
    background-color: transparent;
  }
  .checkboxi_checked {
    background-image: url(../assets/createcheck.svg);
    background-color: var(--color-1);
  }
  .lbl_checkbox {
    font-size: 14px;
    font-family: var(--font-regular);
    cursor: pointer;
  }

//   .checkbox_one:checked {
//     // background-image: url(../assets/eye.png);
//   }

  .checkbox {
    margin-bottom: 10px;
  }



/* Slider */
.flex_slider {
  display: flex;
  justify-content: space-between;
}
.slider_container {
  width: 30px;
  height: 20px;
  background-color: transparent;
  margin-left: 5px;
  transform: translate(0px, 6px);
  margin-right: 10px;
}
.slider_still {
  height: 15px;
  width: 32px;
  border: 1px solid var(--color-2);
  border-radius: 15px;
  background-color: var(--color-2-light);
  transition: 0.2s;
  cursor: pointer;
}
.slider_still_on {
  border-color: var(--color-7);
  background-color: var(--color-7-light-light);
}
.slider_move {
  height: 13px;
  width: 13px;
  border-radius: 22px;
  background-color: black;
  transition: 0.2s;
  cursor: pointer;
}
.slider_off {
  transform: translate(2px, -15px);
}
.slider_on {
  transform: translate(19px, -15px);
  background-color: var(--color-2);
}


