.view_container {
    min-width: calc(90vw - 10vh - 361px);
    height: calc(100vh - 97px - 30px);
    margin-left: 341px;
    padding: 30px 5vw 0 5vw;
    overflow: hidden;
    background-color: transparent;
}

.data_box_container {
    background-color: white;
    border-radius: 5px;
    padding: 8% 8%;
    height: fit-content;
    margin-bottom: 250px;
    border: 1px solid var(--color-9);
}
.data_box_container_disabled {
  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 8% 8%;
  height: fit-content;
  margin-bottom: 250px;
  border: 1px solid transparent;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: lightgrey;
  z-index: 100;
  opacity: 0.3;
  border-radius: 5px;
}

.data_box_title {
    font-size: 20px;
    margin-bottom: 20px;
}

/* Dialog */
.dialog_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #31313180;
    backdrop-filter: blur(5px);
    z-index: 2000;
}
.dialog_box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    min-width: 50vh;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 3% 3.5%;
    display: flex;
    flex-direction: column;
}
