.lbl_regular {
    font-family: var(--font-regular);
    font-size: 14px;
    color: var(--font-color-standard);
}

.lbl_bold {
    font-family: var(--font-regular);
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    color: var(--font-color-standard);
}

.lbl_italic {
    font-family: var(--font-regular);
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: var(--font-color-standard);
}

.lbl_italic_light {
  font-family: var(--font-regular);
  font-size: 12px;
  font-style: italic;
  color: var(--font-color-placeholder);
}

.lbl_invalid {
    font-family: var(--font-regular);
    font-size: 14px;
    color: var(--invalid-color);
    cursor: default;
}

.lbl_color_box_green {
    padding: 4px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-6);
    color: var(--color-6);
    background-color: var(--color-6-light);
    font-family: var(--font-regular);
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    width: max-content;
}

.lbl_color_box_yellow {
    padding: 4px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-4);
    color: var(--color-4);
    background-color: var(--color-4-light);
    font-family: var(--font-regular);
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    width: max-content;
}

.lbl_color_box_red {
    padding: 4px 10px;
    border-radius: 5px;
    border: 1px solid var(--color-5);
    color: var(--color-5);
    background-color: var(--color-5-light);
    font-family: var(--font-regular);
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    width: max-content;
}

.lbl_title_big_color {
    font-family: var(--font-regular);
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    color: var(--color-1);
    margin-bottom: 15px;
}

.lbl_title_small_color {
    font-family: var(--font-regular);
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    color: var(--color-1);
    margin-bottom: 15px;
}

.lbl_data_color {
    font-family: var(--font-regular);
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: var(--color-1);
}

.lbl_regular_data {
    font-family: var(--font-regular);
    font-size: 14px;
    color: var(--font-color-standard);
    margin-bottom: 5px;
}

.lbl_color_disabled {
  color: #A4A5A5;
}
