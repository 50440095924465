.dropdown_container {
    height: 44px;
    margin-bottom: 10px;
}

/* Dropdown Header */
.dropdown_header {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 12px 15px;
    border: 1px solid var(--color-9);
    // width: 250px;
    cursor: pointer;
    transition: 0.15s;
    transition: 0.15s ease-in;
    -moz-transition: 0.15s ease-in;
    -ms-transition: 0.15s ease-in;
    -o-transition: 0.15s ease-in;
    -webkit-transition: 0.15s ease-in;
    transition-delay: 0.15px;
}
.dropdown_header_active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    // border-bottom: none;
}
.dropdown_header_lbl {
    width: -webkit-fill-available;
}
.dropdown_header_lbl_placeholder {
    font-family: var(--font-regular);
    font-style: italic;
    color: var(--font-color-placeholder);
    font-size: 14px;
}
.icon_dropdown {
    padding: 0px 10px;
    transition: 0.15s ease-in;
    -moz-transition: 0.15s ease-in;
    -ms-transition: 0.15s ease-in;
    -o-transition: 0.15s ease-in;
    -webkit-transition: 0.15s ease-in;
}
.icon_dropdown_arrow {
    transform: rotate(270deg);
}
.icon_dropdown_arrow_rotate {
    transform: rotate(90deg);
}
.dropwdown_disabled {
    background-color: var(--color-7-light-light);
    border-color: var(--color-7-light-light);
    cursor: default;
}
.dropdown_valid {
    border-color: var(--color-6);
}

/* Dropdown Body */
.dropdown_body {
    position: relative;
    transform: translate(0px, 0px);
    background-color: white;
    border-radius: 5px;
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1000;
    overflow: scroll;
    max-height: 0;
    // transition: max-height 0.15s ease-in;
    // -moz-transition: max-height 0.15s ease-in;
    // -ms-transition: max-height 0.15s ease-in;
    // -o-transition: max-height 0.15s ease-in;
    // -webkit-transition: max-height 0.15s ease-in;
}
.dropdown_body_open {
    max-height: 180px;
    // border-bottom-color: var(--color-9);
    border-left: 1px solid var(--color-9);
    border-right: 1px solid var(--color-9);
    border-bottom: 1px solid var(--color-9);
}
.dropdown_body_item {
    padding: 12px;
    padding-left: 16px;
    font-size: 14px;
    cursor: pointer;
}
.dropdown_body_item:hover {
    background-color: var(--hover-color);
}
.dropdown_body_item_active {
    background-color: var(--hover-color);
}

.dropdown_title_lbl {
    font-size: 14px;
    padding: 5px 5px;
}
